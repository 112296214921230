import { Card, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";

const VIRGIL_BE_KEY= 'VIRGIL_BACKEND_SERVER';


export function Settings() {
    const [server, setServer] = useState(localStorage.getItem(VIRGIL_BE_KEY) ?? '')

    useEffect(() => {
        localStorage.setItem(VIRGIL_BE_KEY, server)
    })

   
    const saveServer: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setServer(event.target.value)
    }

    return(
        <Card placeholder="" className="fill-gray-100">
            <Typography placeholder="" variant="h1" color="blue-gray" className="text-center">Settings</Typography>
        
            <form className="mt-8 mb-2 w-80 max-w-screen-xl sm:w-96 mx-4">
                <div className="mb-1 flex flex-col gap-6">
                    <Typography placeholder="" variant="h6" color="blue-gray" className="-mb-3">
                        Server
                    </Typography>
                    <Input
                        crossOrigin=""
                        size="lg"
                        placeholder=""
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                        className: "before:content-none after:content-none",
                        }}
                        value={server}
                        onChange={saveServer}
                    />
                </div>
            </form>
        </Card>
    )
}