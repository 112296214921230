import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { ThemeProvider } from "@material-tailwind/react";
import { getVersion } from "./request";

function Root() {
  const [backendVersion, setBackendVersion] = useState({version:""});
  useEffect(()=> {
    getVersion().then((v) => setBackendVersion(v))
  }, [])  
  
  return(
    <React.StrictMode>
    <ThemeProvider>
      <App versionprovider={setBackendVersion}/>
    </ThemeProvider>
    <footer>
      <p className="center">Version: (Frontend: v0.0.5, backend: {backendVersion.version}) </p>
    </footer>
  </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Root/>
);
