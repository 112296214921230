import { Card, IconButton, Tab, TabPanel, Tabs, TabsBody, TabsHeader, Textarea, Typography } from '@material-tailwind/react';
import Editor from '@monaco-editor/react';

import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { virgilRequest, VirgilResponse, virgilRequestData, VirgilResponseFull, VirgilData, VirgilBackendVersion } from './request';

function formatChartData(data: VirgilData): {name: string, [key: string]: any}[] {
  const returnData = [] as {name: string, [key: string]: any}[];
  data.values.forEach((columns: any[]) => {
    const chartRow = {} as {name: string, [key: string]: any};

    // check numeric in header def
    data.headers.forEach((columnName, idx) => {
      chartRow[columnName] = columns[idx];
    })

    returnData.push(chartRow)
  })

  return returnData
}

const Colours = ["#8884d8", "#82ca9d"];

function Chart(props: {data: VirgilData}) {
  return (
      <ResponsiveContainer className="px-2" width="100%" height="100%">
        <LineChart
          className='h-60'
          data={formatChartData(props.data)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={props.data.headers[0]} />
          <YAxis/>
          <Tooltip />
          <Legend />
          {props.data.headers.slice(1).map((columnName, idx) => {
            return (
              <Line type="monotone" dataKey={columnName} stroke={Colours[idx % Colours.length]} activeDot={{ r: 8 }} />
            )
          })}
        </LineChart>
      </ResponsiveContainer>
  )
}

//Todo: add schema to data
export function DefaultTable(props: {data: VirgilData}) {
  return (
    <Card placeholder="" className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {props.data.headers.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography placeholder=""
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.values.map((row, index) => {
            const isLast = index === props.data.values.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
 
            return (
              <tr key={row[0]}>
              {
                row.map( (columnData) => {
                  return (
                    <td className={classes}>
                    <Typography placeholder=""
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {columnData}
                    </Typography>
                  </td>
                )})
              }
              </tr>      
          );
          })}
        </tbody>
      </table>
    </Card>
  );
}

function NotebookCard(props: {value: VirgilResponseFull}) {
  const options = {
      readOnly: false,
      minimap: { enabled: false },
  };
  let shiftModify = true;
  let currentSql: string | undefined = props.value.sql;
  const [data, setData] = useState(props.value.data);

    return (
        <Card placeholder="" className='overflow-y-auto pt-4 m-4'>
        <div onKeyDown={async (event) => {
          shiftModify = event.shiftKey
          if(shiftModify && event.key === "Enter") {
            if(currentSql) {
              setData(await virgilRequestData(currentSql))
            }
          }
        }}>
          <Editor className='mt-6 h-36 resize-y overflow-auto border-b-4 mb-4' theme='vs' defaultLanguage="sql" value={currentSql} options={options} onChange={(sql) => currentSql = sql} />
        </div>
        <div className='m-2'>
            <Tabs value="chart" className='overflow-y-auto'>
                <TabsHeader placeholder="" className='bg-blue-gray-300'>
                    <Tab placeholder="" key="chart" value="chart">Chart</Tab>
                    <Tab placeholder="" key="table" value="table">Table</Tab>
                </TabsHeader>
                <TabsBody placeholder="" className='overflow-y-auto'>
                    <TabPanel className='h-60 mt-4' value="chart">
                        <Chart data={data}/>
                    </TabPanel>
                    <TabPanel className='h-60' value="table">
                        <div><DefaultTable data={data}/></div>
                    </TabPanel>
                </TabsBody>                
            </Tabs>
        </div>  
    </Card>
    )
}

function Loading() {
  return <div>
    <Card placeholder="" className="overflow-y-auto pt-4 m-4 animate-pulse">
    {[...Array(10)].map((x, i) =>
      <Typography placeholder=""
        as="div"
        variant="h1"
        className="mb-4 h-3 w-10/12 ml-4 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
    )}
    </Card>
  </div>
}

function MainArea(props: {value: (VirgilResponse|Loading)[]}) {
    const values = {} as {[id: number] : VirgilResponseFull|Loading} 
    props.value.forEach((v) => {
      if(v.targetNotebookCard !== undefined) {
        values[v.targetNotebookCard] = v as VirgilResponseFull|Loading
      }
    })

    return (<div>        
        {Object.values(values).map((v) => 
          v.loading ? <Loading/> : <NotebookCard key={v.targetNotebookCard} value={v}/>
        )}
        </div>)
}

 
export function ChatboxTextarea(textSetValue: React.Dispatch<React.SetStateAction<(VirgilResponse|Loading)[]>>, versionProvider: React.Dispatch<React.SetStateAction<VirgilBackendVersion>>) {
  const [currentText, setText] = useState("");
  let shiftModify = false;

  const submit = async () => {
    let idx = 0;
    textSetValue((history) => {
      idx = history.length;
      console.log("pressed");
      return [...history, {loading:true, targetNotebookCard: 1}]
    });

    const virgilResponse = await virgilRequest(currentText, versionProvider);
          setText("");

         textSetValue((history) => {
          history[idx] = virgilResponse;
          return history
         })
  }
  return (
    <div className="flex w-full flex-row items-center gap-2 rounded-[99px] border border-gray-900/10 bg-white/80 p-2">
      <Textarea
        rows={1}
        resize={true}
        placeholder="Your Message"
        className="min-h-full !border-0 focus:border-transparent"
        containerProps={{
          className: "grid h-full",
        }}
        onKeyDown={(event) => {
          shiftModify = event.shiftKey
          if(shiftModify && event.key === "Enter") {
            submit()
          }
        }}
        onChange={(event)=>{
          setText(event.target.value);
        }}
        value={currentText}
        labelProps={{
          className: "before:content-none after:content-none",
        }}
      />
      <div>
        <IconButton placeholder="" variant="text" className="rounded-full" onClick={submit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
        </IconButton>
      </div>
    </div>
  );
}

type Loading = {loading: true, targetNotebookCard: number};

export function Sessions(props: {versionProvider: React.Dispatch<React.SetStateAction<VirgilBackendVersion>>}) {
    const [textState,textSetValue] = useState([] as (VirgilResponse|Loading)[]);

    return (
        <div className="grid lg:grid-cols-10">
            <div className="col-span-7"><MainArea value={textState}></MainArea></div>
            <div className="col-span-3 h-screen overflow-scroll">
                <div className="mx-4">
                    {textState.filter((r) => !r.loading).slice(-2).map((text, idx) =>
                      <div key={idx}>
                          <Card placeholder="" className="mb-4 bg-blue-100 h-fit"><Textarea className="h-fit" readOnly={true} value={text.input}/></Card>
                          <Card placeholder="" className="mb-4 h-96 flex-row"><Textarea className='h-96' readOnly={true} value={text.context}/></Card>
                      </div>
                    )}
                    {ChatboxTextarea(textSetValue, props.versionProvider)}
                </div>
            </div>
        </div>
    )
}