export type VirgilData =  {headers: string[], values: any[][]};
export type VirgilResponseFull = {loading:false, targetNotebookCard: number, input: string, sql:string, context:string, data: VirgilData};
export type VirgilResponsePartial = { loading:false, input: string, context:string, targetNotebookCard: number|undefined };
export type VirgilBackendVersion = {version: string} 

export type VirgilResponse = VirgilResponseFull | VirgilResponsePartial; 

export async function virgilRequest(userMessage: string, versionprovider: React.Dispatch<React.SetStateAction<VirgilBackendVersion>>): Promise<VirgilResponse> {
    const response = await fetch('/request', {
        method: 'POST',
        body: JSON.stringify({
            thread_id: "07aa847f-86cd-4c3f-bbaa-3fc1225d22a3",
            message: userMessage
        })
    })

    versionprovider({version: response.headers.get("virgil-version") ?? ''})

    const responseJson = await response.json();
    console.log(responseJson)
    const targetNotebookCard = (responseJson.data === undefined) ? undefined : 1;
    return { input: userMessage, targetNotebookCard, ...responseJson } 
}

export async function virgilRequestData(sql: string): Promise<VirgilData> {
    const response = fetch('/data', {
        method: 'POST',
        body: JSON.stringify({ sql })
    })

    const responseJson = await (await response).json();
    console.log(responseJson)

    return responseJson
}

export async function getVersion(): Promise<VirgilBackendVersion> {
    const response = fetch('/version', {
        method: 'GET',
    })

    const responseJson = await (await response).json();
    console.log(responseJson)

    return responseJson
}