import { Navbar, Typography } from "@material-tailwind/react";
import { Settings } from "./settingForm";
import { useState } from "react";
import { Sessions } from "./session";
import { VirgilBackendVersion } from "./request";

function navList(setState: React.Dispatch<React.SetStateAction<JSX.Element>>, versionprovider: React.Dispatch<React.SetStateAction<VirgilBackendVersion>>) {
  return (
    <div className="mx-auto flex">
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
          placeholder=""
          as="li"
          variant="h1"
          color="blue-gray"
          className="flex items-center gap-x-2 p-1 font-medium hover:bg-blue-100 h-20"
        >
        <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 stroke-black fill-transparent"  viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>


        <button className="flex items-center" onClick={() => setState(<Settings/>)}>
          Settings
        </button>
      </Typography>
      <Typography
        placeholder=""
        as="li"
        variant="h1"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium hover:bg-blue-100 h-20"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 fill-current" viewBox="0 0 470 470"><path d="M338.829 0H18.724a7.5 7.5 0 0 0-7.5 7.5v455a7.5 7.5 0 0 0 7.5 7.5h320.105c20.678 0 37.5-16.822 37.5-37.5v-395c0-20.678-16.822-37.5-37.5-37.5zm22.5 432.5c0 12.406-10.093 22.5-22.5 22.5H26.224V15h312.605c12.407 0 22.5 10.094 22.5 22.5v395z"/><path d="M51.224 37.5v305a7.5 7.5 0 0 0 15 0v-305a7.5 7.5 0 0 0-15 0zM51.224 372.5v60a7.5 7.5 0 0 0 15 0v-60a7.5 7.5 0 0 0-15 0zM298.829 70H128.724a7.5 7.5 0 0 0-7.5 7.5v90a7.5 7.5 0 0 0 7.5 7.5h170.105a7.5 7.5 0 0 0 7.5-7.5v-90a7.5 7.5 0 0 0-7.5-7.5zm-7.5 90H136.224V85h155.105v75z"/><path d="M158.724 115h110.105c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H158.724c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5zM158.724 145h110.105c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H158.724c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5zM458.739 127.566c-.018-.184-.05-.365-.081-.545-.011-.061-.016-.122-.028-.183a7.624 7.624 0 0 0-.166-.662l-.013-.05a7.458 7.458 0 0 0-.28-.767l-19.969-48.034a7.5 7.5 0 0 0-13.85 0l-19.969 48.034c-.107.249-.2.505-.28.767l-.013.05a7.624 7.624 0 0 0-.166.662c-.012.061-.017.122-.028.183-.031.18-.063.361-.081.545a7.443 7.443 0 0 0-.038.747V447.5c0 12.406 10.093 22.5 22.5 22.5h10c12.407 0 22.5-10.094 22.5-22.5V128.313a8.07 8.07 0 0 0-.038-.747zM436.276 455h-10c-4.136 0-7.5-3.364-7.5-7.5V435h25v12.5c0 4.136-3.364 7.5-7.5 7.5zm7.5-319.187V420h-25V135.813h25zm-21.259-15 8.76-21.07 8.76 21.07h-17.52z"/></svg>

        <button className="flex items-center" onClick={() => setState(<Sessions versionProvider={versionprovider}></Sessions>)}>
          Session
        </button>
      </Typography>
  </ul>
  </div>
)
}

function virgilIcon1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-20 fill-current" viewBox="0 0 192.756 192.756">
      <path fill="none" fill-rule="evenodd" d="M0 0h192.756v192.756H0V0z" clip-rule="evenodd"/>
      <path fill="#c52b30" fill-rule="evenodd" d="M83.914 77.121c.439 0 .865-.143 1.256-.379v-6.824a2.637 2.637 0 0 0-1.256-.338c-1.567 0-2.87 1.563-2.87 3.903 0 2.208 1.303 3.638 2.87 3.638zm85.938 31.787c-1.695-.129-1.891-.779-2.086-2.859-.197-2.471-.328-5.979-.328-10.725 0-2.145 0-8.062.652-13.196a62.89 62.89 0 0 1-4.555.603v29.563l9.771-1.434v-1.494l-3.454-.458zm-6.317-31.021c.418.251.881.401 1.359.401 1.566 0 2.871-1.561 2.871-3.901 0-2.211-1.305-3.641-2.871-3.641-.479 0-.941.166-1.359.445v6.696z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="m107.254 81.898-10.339 1.517v1.786l3.603.476c1.541.119 1.742.618 1.938 2.71.219 2.706.328 6.311.328 10.709 0 1.781 0 6.516-.422 11.074l-.018.184h5.104V88.565c1.207-.333 3.289-.851 4.58-.851 1.01 0 2.135.953 3.447 2.915l.088.132.137-.08c1.857-1.085 2.627-1.81 3.58-3.373l.053-.084-.049-.087c-1.566-2.715-2.738-3.724-4.322-3.724-1.967 0-4.436.703-7.514 2.119v-3.663l-.194.029zm77.824-20.039v48.326h4.844V55.187c-3.396.528-6.867.771-10.125.887v1.494l3.457.456c1.693.194 1.824.845 1.824 3.835z" clip-rule="evenodd"/>
      <path fill="#c52b30" fill-rule="evenodd" d="M81.044 88.171c.196 2.473.325 5.981.325 10.728 0 1.881 0 6.672-.441 11.287h4.242V81.944l-9.669 1.418v1.496l3.456.455c1.695.129 1.891.779 2.087 2.858z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M49.173 81.248c-2.596.616-5.675 1.088-8.908 1.363l-.153.012v1.875l2.374.138c1.042.058 1.407.324 2.332 2.625 2.867 7.143 5.043 14.628 7.391 22.97l.034.123h4.562l10.041-25.662-4.033.328-.019.134c-.444 3.296-3.03 11.099-6.588 19.899h-.149l-5.377-18.42c-.63-1.89-1.054-3.604-1.299-5.246l-.026-.18-.182.041zm88.55.514c-5.846.705-9.334 4.587-9.334 10.381 0 4.791 3.52 8.287 8.787 8.869l-6.16 3.467c-1.016 1.006-1.648 2.43-1.648 3.658 0 2.158 1.266 3.4 3.998 4.035l-5.18 4.414a124.2 124.2 0 0 0-.869 1.691l-.125.242.279-.012h4.73l.029-.137c.467-2.363 1.617-4.318 3.611-6.146l7.125.043c3.83 0 6.025 1.812 6.025 4.967l-.119 1.262.184.012h4.75l.039-.113c.422-1.191.635-2.369.635-3.5 0-5.258-4.938-7.123-9.166-7.123h-6.781c-2.311 0-3.482-.205-3.482-1.328 0-1.209.369-1.703 3.754-3.424l3.459-1.82c5.596-2.746 8.436-6.013 8.436-9.707 0-3.096-1.324-5.103-2.318-6.344l-.279-.347-.021.443c-.088 1.803-.52 6.599-1.443 7.143-.082.051-.162.052-.256.006-.418-.204-.984-1.328-1.377-2.155l-.352.082.037 1.172c0 4.524-1.367 6.908-4.719 8.23-4.32-.957-5.963-3.01-5.963-7.58 0-3.111.658-7.042 3.789-8.116l.115-.039V81.74l-.19.022z" clip-rule="evenodd"/>
    </svg>
  )
}

export default function App(props: {versionprovider: React.Dispatch<React.SetStateAction<VirgilBackendVersion>>}) {
  const [state, setState] = useState(<Settings/>)

  return (
    <div>
    <Navbar placeholder="" className="max-w-full px-4 py-0 lg:px-0 lg:py-0">
      <div className="container mx-4 flex items-center text-blue-gray-900">
        {virgilIcon1()}        
        <div className="w-5"/>
        <div className="hidden lg:block">{navList(setState, props.versionprovider)}</div>
      </div>
    </Navbar>
    <div className="max-w-screen-4xl p-10 h-screen">{state}</div>
    </div>
  );
}
